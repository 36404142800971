import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

export const ToastMessage = createSlice({
  name: 'ToastMessage',
  initialState: {
    data: null,
  },
  reducers: {
    showToast(state, action) {
      const { type, text } = action.payload
      if (type === 0) {
        toast.success(text)
      } else if (type === 1) {
        toast.warn(text)
      } else {
        toast.error(text)
      }
    },
  },
})

export const { showToast } = ToastMessage.actions
export default ToastMessage.reducer
