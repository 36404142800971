import { configureStore } from '@reduxjs/toolkit'
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage'
import {combineReducers} from 'redux'
import userReducer from "./redux/user";
import ToastMessage from "./redux/ToastMessage";
import HeaderState from "./redux/HeaderState";
import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, combineReducers({
    user: userReducer,
    ToastMessage:ToastMessage,
    HeaderState:HeaderState
}));

export default configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
    reducer: persistedReducer
})
