import { createSlice } from "@reduxjs/toolkit";

export const HeaderState = createSlice({
  name: "HeaderState",
  initialState: {
    sidebarShow: true,
  },
  reducers: {
    setSidebar(state, action) {
      return { ...state, sidebarShow: action.payload };
    },
   
  },
});

export const { setSidebar } = HeaderState.actions;
export default HeaderState.reducer;
