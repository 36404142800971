import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    userProfile: null,
    logoData: null,
  },
  reducers: {
    setUser(state, action) {
      return { ...state, data: action.payload }
    },
    setUserProfile(state, action) {
      return { ...state, userProfile: action.payload }
    },
    setLogoData(state, action) {
      return { ...state, logoData: action.payload }
    },
  },
})

export const { setUser, setUserProfile, setLogoData } = userSlice.actions
export default userSlice.reducer
