import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import TranslationEN from './translation_lang/TranslationEN.json'
import TranslationFE from './translation_lang/TranslationFE.json'
import TranslationCH from './translation_lang/TranslationCH.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: TranslationEN,
      },
      fe: {
        translations: TranslationFE,
      },
      ch: {
        translations: TranslationCH,
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
